#QrCode {
  margin-bottom: 1em;
  position: relative; 
}

#Large-Title {
  margin: 0;
  font-size: 60px;
}

#Large-Subtitle {
  font-size: 30px;
}

#Title {
  margin: 0;
  font-size: 30px;
}

#Subtitle, #Large-Subtitle {
  margin: 0;
  margin-top: 0.4em;
  font-size: 15px;
  font-weight: "500";
  color: rgba(0, 0, 0, 0.3)
}

.App {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

#button-logo {
  height: 42.05px;
}

nav {
  width: 100%;
  height: 64px;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF; 
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}

#Brand-Container{
  display: inline-flex; 
  width: auto;
  height: 64px; 
}

#Brand-Logo {
  margin: auto; 
  height: 60%;
}

/* 
.Brand-Name {
  margin: auto;
  font-family: 'roxima Nova';
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  padding-left: 8px;
} */

.Main-Container {
  height: 20em;
  margin-top: 11.5em;
}

#Logo-Small {
  width: 29px;
  height: 29px;
  position: absolute;
}


#Buttons-Section {
  /* border: 1px solid green; */
  width: 100%;
  height: 5em;
  position: absolute;
  bottom: 2em;
  /* margin-top: 10%; */
  justify-content: center; 
}

#Buttons-Section a {
  text-decoration: none;
}

.Button-Container {
  display: inline-flex; 
  text-align: center;
  justify-content: center;
  min-width: 185px;
  max-width: 185px;
  height: 52px;
  margin: auto 30px; 
  color: #FFFFFF;
  background: #0084FF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  transition: 0.5s;
}

.Button-Container:hover {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
}

.Button-Logo {
  margin: auto 3px;
  height: 24px;   
}

.Button-Text {  
  margin: auto 3px;
  font-family:'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px; 
}

/* #Loading-Container {
  height: 21em;
} */


@media only screen and (max-width: 600px) {
  body {  
    padding: auto;
  }

  #Buttons-Section {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0; 
    justify-content: center; 
  }

  .Main-Container {
    height: auto;
    margin-top: 4em;
    margin-bottom: 4em;
  }

  .Button-Container {
    margin-bottom: 2em;
  }
}